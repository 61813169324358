<template>
  <div class="keys">
    <v-data-table
      :headers="headers"
      :items="keys"
      :no-data-text="noData"
      :items-per-page="10"
      :footer-props="{
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 25, 50, 100],
        itemsPerPageText: 'Accesos por página',
      }"
      class="elevation-1"
      show-expand
      single-expand
      :loading="loading"
      loading-text="Buscando llaves ..."
    >
      <template v-slot:top>
        <v-toolbar elevation="1" :height="responsive_height">
          <v-dialog max-width="500px">
            <template v-slot:activator="{}">
              <v-container>
                <v-row align="center">
                  <v-col cols="12" sm="6" class="d-flex">
                    <v-switch
                      v-model="all_building"
                      inset
                      label="Todas las unidades"
                      color="green"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" class="d-flex">
                    <v-select
                      v-model="range"
                      :items="ranges"
                      label="Período"
                      item-text="name"
                      item-value="id"
                      :color="whiteLabel.colors.primary"
                      :item-color="whiteLabel.colors.primary"
                      filled
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-select
                  v-model="floor"
                  :items="floors"
                  label="Planta"
                  item-text="name"
                  item-value="id"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  filled
                  dense
                  :disabled="all_building"
                ></v-select>
                <v-select
                  v-model="unit"
                  :items="units"
                  label="Unidad"
                  item-text="name"
                  item-value="id"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  filled
                  dense
                  :disabled="all_building"
                ></v-select>
              </v-container>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div v-if="item.total == -1">Usos: Ilimitados</div>
          <div v-if="item.total != -1">Usos: {{ item.used}} de {{ item.total }}</div>
          <div v-if="item.valid_from && item.valid_to">Validez: desde {{ new Date(item.valid_from.seconds * 1000).toLocaleDateString('es-AR') }} hasta {{ new Date(item.valid_to.seconds * 1000).toLocaleDateString('es-AR') }}</div>
          <div v-if="!item.valid_from || !item.valid_to">Validez: Ilimitada</div>
          <div v-if="item.week_days_time">Días y horarios: {{ weekDaysTimeFormat(item.week_days_time) }}</div>
        </td>
      </template>

      <template v-slot:item.image="{ item }">
        <img :src="item.image" class="thumbnail" />
      </template>

      <template v-slot:item.datetime="{ item }">
        <span>{{ new Date(item.datetime).toLocaleString("es-AR") }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 'enabled and active'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="green"
                >mdi-check-circle</v-icon
              >
            </template>
            <span>Llave Habilitada y Activa</span>
          </v-tooltip></span
        >
        <span v-if="item.status == 'enabled and inactive due to date'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <!--
              <v-icon v-bind="attrs" v-on="on" color="green"
                >mdi-check-circle</v-icon
              >
              -->
              <v-icon v-bind="attrs" v-on="on" color="red"
                >mdi-calendar-remove</v-icon
              >
            </template>
            <span>Llave Habilitada pero Inactiva por fecha</span>
          </v-tooltip></span
        >
        <span v-if="item.status == 'enabled and inactive due to max uses'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red"
                >mdi-cancel</v-icon
              >
            </template>
            <span>Llave Habilitada pero Inactiva por cantidad de usos</span>
          </v-tooltip></span
        >
        <span v-if="item.status == 'disabled'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red"
                >mdi-close-box</v-icon
              >
            </template>
            <span>Llave Deshabilitada</span>
          </v-tooltip></span
        >
      </template>
      <template v-slot:item.action="{ item }">
        <span v-if="item.status == 'disabled'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="green" @click="setKeyStatus(item.path, true)"
                >mdi-key-plus</v-icon
              >
            </template>
            <span>Habilitar llave</span>
          </v-tooltip></span
        >
        <span v-if="item.status == 'enabled and active'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red" @click="setKeyStatus(item.path, false)"
                >mdi-key-remove</v-icon
              >
            </template>
            <span>Inhabilitar llave</span>
          </v-tooltip></span
        >
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "@/fb";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    floor: null,
    floors: [],
    unit: null,
    units: [],
    keys: [],
    all_contacts: [],
    all_units: [],
    all_floors: [],
    all_doors: [],
    all_building: false,
    range: 30,
    ranges: [
      {
        name: "Ultimo mes",
        id: 30,
      },
      {
        name: "Ultimos 3 meses",
        id: 90,
      },
      {
        name: "Ultimos 6 meses",
        id: 180,
      },
      {
        name: "Ultimo año",
        id: 365,
      },
      {
        name: "Todo",
        id: null,
      },
    ],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    headers: [
      { text: "Planta", align: "start", sortable: false, value: "floor" },
      { text: "Unidad", align: "start", sortable: false, value: "unit" },
      { text: "Nombre", align: "start", sortable: false, value: "name" },
      { text: "Estado", align: "start", sortable: false, value: "status" },
      { text: "Acción", align: "start", sortable: false, value: "action" },
      { text: "Más", value: "data-table-expand", sortable: false },
    ],
    noData: "Seleccione planta y unidad",
  }),

  props: ["initial_floor", "initial_unit"],

  created() {
    this.getAllFloors();
    //this.getAllContacts();
    this.getAllUnits();
    //this.getAllDoors();
  },

  mounted() {
    this.getFloors();
    this.floor = this.initial_floor;
    this.unit = this.initial_unit;
  },

  computed: {
    ...mapGetters({
      user: "user",
      whiteLabel: "whiteLabel",
      license: "license",
    }),

    responsive_height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 360;
        case "sm":
          return 320;
        case "md":
          return 300;
        case "lg":
          return 280;
      }
      return 360;
    },
  },

  watch: {
    floor() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando unidades ...";
      this.snackbar.color = "success";
      this.getUnits();
    },
    unit() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando llaves ...";
      this.snackbar.color = "success";
      this.getVirtualKeys();
    },
    all_building() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando llaves ...";
      this.snackbar.color = "success";
      this.getVirtualKeys();
    },
    range() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando accllavesesos ...";
      this.snackbar.color = "success";
      this.getVirtualKeys();
    },
  },

  methods: {
    async getFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            this.floors = [];
            let floor_positions = null;

            db.doc("buildings/" + this.user.data.building)
              .get()
              .then((floor) => {
                floor_positions = floor.data().floor_positions;

                let unsorted_floors = [];
                floors.forEach((floor) => {
                  unsorted_floors.push(floor);
                });

                floor_positions.forEach((floor) => {
                  let f = unsorted_floors.find((f) => f.id === floor);
                  this.floors.push({ name: f.data().name, id: f.id });
                });
              });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getUnits() {
      try {
        if (this.initial_unit) {
          this.initial_unit = null;
        } else {
          this.unit = null;
          this.unit_mode = null;
        }
        await db
          .collection(
            "buildings/" +
              this.user.data.building +
              "/floors/" +
              this.floor +
              "/apartments"
          )
          .get()
          .then((units) => {
            this.units = [];
            let unit_positions = null;

            if (!units.empty) {
              db.doc(
                "buildings/" + this.user.data.building + "/floors/" + this.floor
              )
                .get()
                .then((floor) => {
                  unit_positions = floor.data().apartment_positions;
                  let unsorted_units = [];
                  units.forEach((unit) => {
                    unsorted_units.push(unit);
                  });
                  unit_positions.forEach((unit) => {
                    let u = unsorted_units.find((u) => u.id === unit);
                    this.units.push({
                      name: u.data().name,
                      id: u.id,
                      mode: u.data().mode,
                      alias: u.data().alias,
                      remarks: u.data().remarks,
                    });
                  });
                });
            } else {
              if (this.unit) {
                this.noData = "No existen unidades para esta planta";
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    /*
    async getAllContacts() {
      try {
        await db
          .collectionGroup("contacts")
          .where("build_id", "==", this.user.data.building)
          .get()
          .then((contacts) => {
            contacts.forEach((contact) => {
              this.all_contacts.push({
                id: contact.id,
                name: contact.data().name,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    */
    async getAllUnits() {
      try {
        await db
          .collectionGroup("apartments")
          .where("build_id", "==", this.user.data.building)
          .get()
          .then((units) => {
            units.forEach((unit) => {
              this.all_units.push({
                id: unit.id,
                name: unit.data().name,
                mode: unit.data().mode,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            floors.forEach((floor) => {
              this.all_floors.push({
                id: floor.id,
                name: floor.data().name,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getVirtualKeys() {
      try {
        this.loading = true;
        this.keys = [];

        var query = db
          .collectionGroup("keys")
          .where("build_id", "==", this.user.data.building);
        if (!this.all_building) {
          query = query.where("apartment_id", "==", this.unit);
        }
        if (this.range) {
          var today = new Date();
          query = query.where(
            "datetime",
            ">=",
            new Date(today.setDate(today.getDate() - this.range))
          );
        }
        query = query.orderBy("datetime", "desc");
        await query.get().then((keys) => {
          if (!keys.empty) {
            // Get current time removing the miliseconds to compare with Firebase timestamp.
            var currentTime = Number(Date.now().toString().slice(0, -3));

            keys.forEach((key) => {
              var validFrom = true;
              if (key.data().valid_from) {
                var seconds_from = key.data().valid_from.seconds;
                if (seconds_from > currentTime) {
                  validFrom = false;
                }
              }

              var validTo = true;
              if (key.data().valid_to) {
                var seconds_to = key.data().valid_to.seconds;
                if (seconds_to < currentTime) {
                  validTo = false;
                }
              }

              var status = "";
              var total = key.data().total ? key.data().total : 0;
              var used = key.data().used ? key.data().used : 0;

              if (!key.data().enabled) {
                status = "disabled";
              }
              if (key.data().enabled && (!validFrom || !validTo)) {
                status = "enabled and inactive due to date";
              }
              if (key.data().enabled && total >= used) {
                status = "enabled and inactive due to max uses";
              }
              if (key.data().enabled && validFrom && validTo && total < used) {
                status = "enabled and active";
              }
              this.keys.push({
                id: key.id,
                floor: this.all_floors.find(
                  (floor) => floor.id === key.ref.path.split("/")[3]
                ).name
                  ? this.all_floors.find(
                      (floor) => floor.id === key.ref.path.split("/")[3]
                    ).name
                  : "",
                unit: this.all_units.find(
                  (unit) => unit.id === key.ref.path.split("/")[5]
                ).name
                  ? this.all_units.find(
                      (unit) => unit.id === key.ref.path.split("/")[5]
                    ).name
                  : "",
                name: key.data().name,
                status: status,
                datetime: key.data().datetime.toDate(),
                valid_from: key.data().valid_from,
                valid_to: key.data().valid_to,
                week_days_time: key.data().week_days_time,
                total: key.data().total,
                used: key.data().used ? key.data().used : 0,
                path: key.ref.path,
              });
            });
          } else {
            this.noData = "No existen llaves para los filtros seleccionados";
          }
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async setKeyStatus(path, status) {
      try {
        this.loading = true;
        this.snackbar.visible = true;
        if (status) {
          this.snackbar.text = "Habilitando llave ...";
        } else {
          this.snackbar.text = "Inhabilitando llave ...";
        }

        this.snackbar.color = "success";
        await db
          .doc(path)
          .update({
            enabled: status,
          })
          .then(() => {
            this.loading = false;
            this.isValid = false;
            this.getVirtualKeys();
          });
      } catch (error) {
        console.log(error);
      }
    },

    weekDaysTimeFormat(data) {
      var weekDaysTime = "";
      var weekDay = "";
      var hourFrom = "";
      var hourTo = "";
      var index = 0;
      var x = new Map();
      for (const [day, hours] of Object.entries(data)) {
        switch (day) {
          case 'mo':
            weekDay = "Lunes";
            index = 1;
            break;
          case 'tu':
            weekDay = "Martes";
            index = 2;
            break;
          case 'we':
            weekDay = "Miercoles";
            index = 3;
            break;
          case 'th':
            weekDay = "Jueves";
            index = 4;
            break;
          case 'fr':
            weekDay = "Viernes";
            index = 5;
            break;
          case 'sa':
            weekDay = "Sabado";
            index = 6;
            break;
          case 'su':
            weekDay = "Domingo";
            index = 7;
            break;
        }
        var hour = hours.toString();
        if (hour.length == 3) {
          hour = '0' + hour;
        }
        if (hour.length == 1) {
          hour = '000' + hour;
        }
        hourFrom = hour.substring(0, 2);
        hourTo = hour.substring(2, 4);
        weekDaysTime = weekDay + ' de ' + hourFrom + ' a ' + hourTo + 'hs - ';
        x.set(index, weekDaysTime);
      }
      x = new Map([...x.entries()].sort());
      weekDaysTime = "";
      for (let [, value] of x) {
       weekDaysTime += value;
      }
      return weekDaysTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-start img.thumbnail {
  border-radius: 50%;
  width: 50px;
}
</style>
