<template>
  <div>
    <v-tabs
      v-model="tab"
      :color="whiteLabel.colors.primary"
      icons-and-text
      show-arrows
      center-active
      fixed-tabs
      align-with-title
    >
      <v-tab key="callTab">
        Llamadas
        <v-icon>mdi-phone-log</v-icon>
      </v-tab>

      <v-tab
        key="accessTab"
        v-if="license.doors > 0 || license.rfid"
      >
        Accesos
        <v-icon>mdi-door-open</v-icon>
      </v-tab>

      <v-tab key="helpTab">
        S.O.S.
        <v-icon>mdi-lifebuoy</v-icon>
      </v-tab>

      <v-tab
        key="virtualKeysTab"
        v-if="license.doors > 0 || license.rfid"
      >
        Llaves Virtuales
        <v-icon v-if="license.gate">mdi-qrcode-scan</v-icon>
        <v-icon v-if="license.virtual || license.lite">mdi-key-link</v-icon>
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="callTab">
        <v-container>
          <CallLogs
            :initial_floor="initial_floor"
            :initial_unit="initial_unit"
          ></CallLogs>
        </v-container>
      </v-tab-item>

      <v-tab-item
        key="accessTab"
        v-if="license.doors > 0 || license.rfid"
      >
        <v-container>
          <AccessLogs
            :initial_floor="initial_floor"
            :initial_unit="initial_unit"
          ></AccessLogs>
        </v-container>
      </v-tab-item>

      <v-tab-item key="helpTab">
        <v-container>
          <HelpLogs
            :initial_floor="initial_floor"
            :initial_unit="initial_unit"
          ></HelpLogs>
        </v-container>
      </v-tab-item>

      <v-tab-item
        key="virtualKeysTab"
        v-if="license.doors > 0 || license.rfid"
      >
        <v-container>
          <VirtualKeysLogs
            :initial_floor="initial_floor"
            :initial_unit="initial_unit"
          ></VirtualKeysLogs>
        </v-container>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CallLogs from "@/components/CallLogs";
import AccessLogs from "@/components/AccessLogs";
import HelpLogs from "@/components/HelpLogs";
import VirtualKeysLogs from "@/components/VirtualKeysLogs";

export default {
  components: {
    CallLogs,
    AccessLogs,
    HelpLogs,
    VirtualKeysLogs,
  },
  data: () => ({
    tab: 0,
  }),

  props: ["initial_floor", "initial_unit"],

  computed: {
    ...mapGetters({
      user: "user",
      license: "license",
      whiteLabel: "whiteLabel",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
