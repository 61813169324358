<template>
  <div class="accesses">
    <v-data-table
      :headers="headers"
      :items="accesses"
      :no-data-text="noData"
      :items-per-page="10"
      :footer-props="{
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 25, 50, 100],
        itemsPerPageText: 'Accesos por página',
      }"
      class="elevation-1"
      show-expand
      single-expand
      :loading="loading"
      loading-text="Buscando accesos ..."
    >
      <template v-slot:top>
        <v-toolbar elevation="1" :height="responsive_height">
          <v-dialog max-width="500px">
            <template v-slot:activator="{}">
              <v-container>
                <v-row align="center">
                  <v-col cols="12" sm="6" class="d-flex">
                    <v-switch
                      v-model="all_building"
                      inset
                      label="Todas las unidades"
                      color="green"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" class="d-flex">
                    <v-select
                      v-model="range"
                      :items="ranges"
                      label="Período"
                      item-text="name"
                      item-value="id"
                      :color="whiteLabel.colors.primary"
                      :item-color="whiteLabel.colors.primary"
                      filled
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-select
                  v-model="floor"
                  :items="floors"
                  label="Planta"
                  item-text="name"
                  item-value="id"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  filled
                  dense
                  :disabled="all_building"
                ></v-select>
                <v-select
                  v-model="unit"
                  :items="units"
                  label="Unidad"
                  item-text="name"
                  item-value="id"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  filled
                  dense
                  :disabled="all_building"
                ></v-select>
              </v-container>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div v-if="item.contact">
            Acceso concedido por: {{ item.contact }}
          </div>
          <div v-if="item.type == 0">Acceso desde llamada</div>
          <div v-if="item.type == 1">Acceso directo desde dispositivo</div>
          <div v-if="item.type == 2">Acceso desde frente de calle con PIN</div>
          <div v-if="item.type == 3">Acceso bloqueado por multiples intentos</div>
          <div v-if="item.type == 4">Acceso desde frente de calle con PIN Antientradera</div>
          <div v-if="item.type == 5">Acceso desde la Lllave Virtual "{{ item.key_name  }}"</div>
          <div v-if="item.image"><img :src="item.image" /></div>
        </td>
      </template>

      <template v-slot:item.image="{ item }">
        <img :src="item.image" class="thumbnail" />
      </template>

      <template v-slot:item.datetime="{ item }">
        <span>{{ new Date(item.datetime).toLocaleString("es-AR") }}</span>
      </template>

      <template v-slot:item.type="{ item }">
        <span v-if="item.type == 0">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="green"
                >mdi-cellphone-wireless</v-icon
              >
            </template>
            <span>Acceso desde video llamada</span>
          </v-tooltip></span
        >
        <span v-if="item.type == 1">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="green"
                >mdi-cellphone-key</v-icon
              ></template
            >
            <span>Acceso desde dispositivo</span>
          </v-tooltip></span
        >
        <span v-if="item.type == 2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="green"
                >mdi-dialpad</v-icon
              >
            </template>
            <span>Acceso por PIN</span>
          </v-tooltip></span
        >
        <span v-if="item.type == 3"
          ><v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red"
                >mdi-door-closed-lock</v-icon
              ></template
            >
            <span>Acceso bloqueado</span>
          </v-tooltip></span
        >
        <span v-if="item.type == 4"
          ><v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red"
                >mdi-shield-key-outline</v-icon
              ></template
            >
            <span>Acceso anti-entradera</span>
          </v-tooltip></span
        >
        <span v-if="item.type == 5"
          ><v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="license.gate" v-bind="attrs" v-on="on" color="green"
                >mdi-qrcode-scan</v-icon
              >
              <v-icon v-if="license.virtual || license.lite" v-bind="attrs" v-on="on" color="green"
                >mdi-key-link</v-icon
              ></template
            >
            <span>Acceso desde Llave Virtual</span>
          </v-tooltip></span
        >
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "@/fb";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    floor: null,
    floors: [],
    unit: null,
    units: [],
    accesses: [],
    all_contacts: [],
    all_units: [],
    all_floors: [],
    all_doors: [],
    all_building: false,
    range: 30,
    ranges: [
      {
        name: "Ultimo mes",
        id: 30,
      },
      {
        name: "Ultimos 3 meses",
        id: 90,
      },
      {
        name: "Ultimos 6 meses",
        id: 180,
      },
      {
        name: "Ultimo año",
        id: 365,
      },
      {
        name: "Todo",
        id: null,
      },
    ],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    headers: [
      { text: "", align: "start", sortable: false, value: "image" },
      { text: "Planta", align: "start", sortable: false, value: "floor" },
      { text: "Unidad", align: "start", sortable: false, value: "unit" },
      { text: "Tipo", align: "start", sortable: false, value: "type" },
      { text: "Acceso", align: "start", sortable: false, value: "door" },
      { text: "Fecha", align: "start", sortable: false, value: "datetime" },
      { text: "Más", value: "data-table-expand", sortable: false },
    ],
    noData: "Seleccione planta y unidad",
  }),

  props: ["initial_floor", "initial_unit"],

  created() {
    this.getAllFloors();
    this.getAllContacts();
    this.getAllUnits();
    this.getAllDoors();
  },

  mounted() {
    this.getFloors();
    this.floor = this.initial_floor;
    this.unit = this.initial_unit;
  },

  computed: {
    ...mapGetters({
      user: "user",
      whiteLabel: "whiteLabel",
      license: "license",
    }),

    responsive_height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 360;
        case "sm":
          return 320;
        case "md":
          return 300;
        case "lg":
          return 280;
      }
      return 360;
    },
  },

  watch: {
    floor() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando unidades ...";
      this.snackbar.color = "success";
      this.getUnits();
    },
    unit() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando accesos ...";
      this.snackbar.color = "success";
      this.getAccesses();
    },
    all_building() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando accesos ...";
      this.snackbar.color = "success";
      this.getAccesses();
    },
    range() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando accesos ...";
      this.snackbar.color = "success";
      this.getAccesses();
    },
  },

  methods: {
    async getFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            this.floors = [];
            let floor_positions = null;

            db.doc("buildings/" + this.user.data.building)
              .get()
              .then((floor) => {
                floor_positions = floor.data().floor_positions;

                let unsorted_floors = [];
                floors.forEach((floor) => {
                  unsorted_floors.push(floor);
                });

                floor_positions.forEach((floor) => {
                  let f = unsorted_floors.find((f) => f.id === floor);
                  this.floors.push({ name: f.data().name, id: f.id });
                });
              });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getUnits() {
      try {
        if (this.initial_unit) {
          this.initial_unit = null;
        } else {
          this.unit = null;
          this.unit_mode = null;
        }
        await db
          .collection(
            "buildings/" +
              this.user.data.building +
              "/floors/" +
              this.floor +
              "/apartments"
          )
          .get()
          .then((units) => {
            this.units = [];
            let unit_positions = null;

            if (!units.empty) {
              db.doc(
                "buildings/" + this.user.data.building + "/floors/" + this.floor
              )
                .get()
                .then((floor) => {
                  unit_positions = floor.data().apartment_positions;
                  let unsorted_units = [];
                  units.forEach((unit) => {
                    unsorted_units.push(unit);
                  });
                  unit_positions.forEach((unit) => {
                    let u = unsorted_units.find((u) => u.id === unit);
                    this.units.push({
                      name: u.data().name,
                      id: u.id,
                      mode: u.data().mode,
                      alias: u.data().alias,
                      remarks: u.data().remarks,
                    });
                  });
                });
            } else {
              if (this.unit) {
                this.noData = "No existen unidades para esta planta";
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllContacts() {
      try {
        await db
          .collectionGroup("contacts")
          .where("build_id", "==", this.user.data.building)
          .get()
          .then((contacts) => {
            contacts.forEach((contact) => {
              this.all_contacts.push({
                id: contact.id,
                name: contact.data().name,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllUnits() {
      try {
        await db
          .collectionGroup("apartments")
          .where("build_id", "==", this.user.data.building)
          .get()
          .then((units) => {
            units.forEach((unit) => {
              this.all_units.push({
                id: unit.id,
                name: unit.data().name,
                mode: unit.data().mode,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            floors.forEach((floor) => {
              this.all_floors.push({
                id: floor.id,
                name: floor.data().name,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllDoors() {
      try {
        await db
          .doc("buildings/" + this.user.data.building)
          .get()
          .then((building) => {
            for (let i = 0; i <= 2; i++) {
              this.all_doors.push({
                id: i,
                name: building.data().settings["door" + (i + 1) + "_name"],
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAccesses() {
      try {
        this.loading = true;
        this.accesses = [];

        var query = db
          .collectionGroup("accesses")
          .where("build_id", "==", this.user.data.building);
        if (!this.all_building) {
          query = query.where("apartment_id", "==", this.unit);
        }
        if (this.range) {
          var today = new Date();
          query = query.where(
            "datetime",
            ">=",
            new Date(today.setDate(today.getDate() - this.range))
          );
        }
        query = query.orderBy("datetime", "desc");
        await query.get().then((accesses) => {
          if (!accesses.empty) {
            accesses.forEach((access) => {
              this.accesses.push({
                id: access.id,
                floor: this.all_floors.find(
                  (floor) => floor.id === access.ref.path.split("/")[3]
                ).name
                  ? this.all_floors.find(
                      (floor) => floor.id === access.ref.path.split("/")[3]
                    ).name
                  : "",
                unit: this.all_units.find(
                  (unit) => unit.id === access.ref.path.split("/")[5]
                ).name
                  ? this.all_units.find(
                      (unit) => unit.id === access.ref.path.split("/")[5]
                    ).name
                  : "",
                type: access.data().type,
                key_name: access.data().key_name ? access.data().key_name : "",
                datetime: access.data().datetime.toDate(),
                door: this.all_doors[access.data().door]
                  ? this.all_doors[access.data().door].name
                  : "",
                contact: this.all_contacts.find(
                  (contact) => contact.id === access.data().user_id
                )
                  ? this.all_contacts.find(
                      (contact) => contact.id === access.data().user_id
                    ).name
                  : "",
                image: access.data().image
                  ? "data:image/jpeg;charset=utf-8;base64, " +
                    access.data().image.toBase64()
                  : "",
              });
            });
          } else {
            this.noData = "No existen registros para los filtros seleccionados";
          }
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-start img.thumbnail {
  border-radius: 50%;
  width: 50px;
}
</style>
